<template>
    <template v-if="groupByLevels.length < 5">
        <button class="btn btn-primary btn-sm" v-for="level in groupByLevels" :key="level"
            :class="{'active': currentLevel == level}" @click="() => { expandTo(level); }">
            {{level+1}}
        </button>
        <button class="btn btn-primary btn-sm"
            :class="{'active': currentLevel == groupByLevels.length}" @click="() => { expandTo(groupByLevels.length); }">
            {{groupByLevels.length + 1}}
        </button>
    </template>
    <ODropdown v-else>
        <template #default="pinScope">
            <slot v-bind="pinScope">
                <button class="dropdown-item" href="javascript:void(0)" :ref="pinScope.target"
                    @click="pinScope.open">{{ $t('Expand To Level') }}
                    <i class="bi bi-caret-right-fill float-end"></i>
                </button>
            </slot>
        </template>
        <template #dropdown="pinScope">
            <div :ref="pinScope.container" class="card shadow dropdown-menu rounder-0">
                <button class="dropdown-item" v-for="level in groupByLevels" :key="level"
                    @click="() => { expandTo(level); closeFn ? closeFn() : pinScope.close(); }">
                    <i class="bi bi-caret-down opacity-0"></i>
                    {{ $t('Level') }} <span class="text-decoration-underline">{{ level + 1 }}</span>
                </button>
                <button class="dropdown-item"
                    @click="() => { expandTo(deepestLevel); closeFn ? closeFn() : pinScope.close(); }">
                    <i class="bi bi-caret-down"></i>
                    {{ $t('All Levels') }}
                </button>
            </div>
        </template>
    </ODropdown>
</template>

<script lang="ts">
export interface IProps {
    dataObject: DataObject,
    closeFn?: () => void
};
</script>

<script setup lang="ts">
import type { DataObject } from 'o365-dataobject';
import { ODropdown } from 'o365-ui-components';

import { computed } from 'vue';

const props = defineProps<IProps>();

const deepestLevel = computed(() => props.dataObject.nodeData?.deepestLevel ?? 0);
const currentLevel = computed(() => props.dataObject.nodeData?.currentLevel ?? 0);
const groupByLevels = computed(() => {
    const levels: number[] = [];
    for (let i = 0; i < deepestLevel.value; i++) {
        levels.push(i);
    }
    return levels;
});

function expandTo(level: number) {
    props.dataObject?.nodeData?.expandToLevel(level);
}

</script>